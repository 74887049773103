.number-item {
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    padding: 5px;
    margin: 0 3px;
    display: inline-block;
}
.number-item i {
    position: relative;
    z-index: 2;
    font-style: normal;
}
.number-item::after,.number-item::before {
    content: '';
    z-index: 1;
    display: block !important;
    width: 100%;
    height: 48%;
    background-color: #380498;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
}
.number-item::after {
    top: auto;
    bottom: 0;
}
