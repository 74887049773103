.main-bottom {
    position: relative;
}

.main-bottom::before,
.main-bottom::after{
    content: '';
    display: block;
    height: 52px;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../assets/images/line6.png) no-repeat left bottom;
}

.main-bottom::after {
    left: auto;
    right: 0;
    background: url(../../assets/images/line7.png) no-repeat right bottom;
}