.nav {
    height: 95px;
    overflow: hidden;
    background: url(../../assets/images/bg-nav.png) no-repeat center / 100%;
}
.nav-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #fff;
    letter-spacing: 2px;
    padding-top: 19px;
}

.nav-title img {
    margin-right: 10px;
    width: 44px;
    margin-top: 5px;
}
.nav-right {
    width: 100%;
}
.nav-time {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 40px 30px 0 0;
}
.nav-right img {
    width: 50px;
    margin-top: -2px;
}
.nav-time div {
    /* width: 80px; */
}
.nav-time>span {
    width: 80px;
    font-size: 36px;
    font-weight: 700; 
    padding-right: 30px;
    border-right: 1px solid #e5e5e5;
    margin-right: 12px;
    line-height: 0.94;
}

.nav-notice {
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 18px;
    padding-top: 60px;
}
.nav-notice img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d1014e;
    padding: 3px;
    margin-right: 8px;
}