* {
    margin: 0;
    padding: 0;
}
body {
    background-color: #080325;
    color: #fff;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    box-shadow: -1px -1px 3px rgba(0,0,0,.1);
  }

  ::-webkit-scrollbar-thumb {
    border: 0px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--color-text-4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text-3);
  }

.title-line {
    background: url(../assets/images/line2.png) no-repeat left bottom / auto;
    padding: 0 0 6px 105px;
    display: flex;
    justify-content: right;
    align-items: end;
}
.title-left {
    flex: 1;
    font-size: 20px;
}

.title-left small{
    margin-left: 4px;
    font-size: 14px;
}

.title-right {
    padding: 7px 10px;
    
    text-align: center;
    background-color: #1d034e;
    border: 1px solid #fff;
    margin-bottom: -2px;
}

.title-center {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
    background: url(../assets/images/line5.png) no-repeat center bottom / 100% auto;
}

.custom-table .arco-table-th{
    background-color: #131d38;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
.custom-table .arco-table-th-item{
    padding: 6px 8px;
}
.custom-table .arco-table-th small{
    font-weight: 500;
    font-size: 15px;
    margin-left: 2px;
}

.custom-table .arco-table-header {
    background-color: #131d38;
}
.custom-table .arco-table-body{
    background-color: #23025f;
}
.custom-table {
    box-shadow: 0 !important;
    border: 1px solid #fff;
}
.custom-table * {
    border: 0 !important;
    box-shadow: 0 !important;
}
.custom-table *::after,
.custom-table *::before {
    display: none;
}

.custom-table .arco-table-td {
    background-color: #23025f;
    color: #fff;
    font-size: 20px;
    padding: 6px 8px;
}

.arco-table-stripe .arco-table-tr:nth-child(even) .arco-table-td{
    background: rgba(0,0,0,.2) !important;
}

.custom-table .cell-bg-blue,
.custom-table .cell-bg-red {
    border: 1px solid #fff !important;
    background: #103a87;
    width: 80px;
    display: block;
    margin: 0 auto;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.custom-table .cell-bg-red {
    background-color: #f00;
    width: 128px;
}

.cell-blue-radius {
    border-radius: 30px;
    background-color: #155ccf;
    display: inline-block;
    padding: 4px 10px;
    font-size: 18px;
    font-weight: 500;
}

.custom-table-bottom {
    border: 0;
}
.custom-table-bottom .arco-table-body{
    border: 1px solid #fff !important;
    border-top: 0px solid #fff !important;
}
.custom-table-bottom.custom-table .arco-table-th {
    border-bottom: 1px solid #fff !important;
}

.custom-table-bottom .arco-table-header,
.custom-table-bottom.custom-table .arco-table-th{
    background: none;
    font-size: 24px;
}

.origin-table-header.custom-table-bottom.custom-table .arco-table-th {
    color: #ffcc00;
    font-size: 16px;
}
.origin-table-header {
    margin: -10px 0 15px;
}
.origin-table-header.custom-table-bottom.custom-table .arco-table-th-item{
    padding: 0px 0 0px;
}
