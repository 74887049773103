.app {
    width: 1920px;
    height: 1080px;
    position: absolute;
    transform-origin: left top;
}

.app-inner-box {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}