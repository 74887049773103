.main-center .title-line {
    background: url(../../assets/images/line1.png) no-repeat left bottom / auto;
}

.main-center .title-left {
    font-size: 28px;
}

.main-center .title-left small{
    font-size: 14px;
}

.main-center-pannel .numShow {
    display: inline-block;
    padding: 5px;
    border: 1px solid #fff;
}

.main-center-pannel .numShow .inner {
    background-color: #0f1932;
    padding: 8px 5px;
}

.center-num .title-line {
    background: url(../../assets/images/line4.png) no-repeat left bottom / auto;
}

.center-num{
    padding: 30px 0 0;
}
.center-num-box {
    border: 20px solid #fff;
    background-color: #ff0000;
    font-size: 120px;
    font-weight: 700;
    text-align: center;
}

.center-title {
    text-align: center;
    font-size: 48px;
    margin: 10px 0 0px;
    background: url(../../assets/images/line5.png) no-repeat center bottom / auto;
}

