.main-left {
    margin-right: 30px;
}
.main-left .twoDot {
    position: relative;
}
.main-left .twoDot::after{
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    bottom: 18px;
    right: 65px;
    background-color: #fff;
}
