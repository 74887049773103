.bg-wrapper {
    background: url(../../assets/images/banner_bg.jpg) no-repeat center / 100%;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
}

.bg-wrapper::after{
    content: '';
    background: url(../../assets/images/banner_bgmask.png) no-repeat center / 100%;
    width: 100%;
    height: 100%;
    top:0 ;
    left: 0;
    position: absolute;
}

.bg-circle {
    width: 720px;
    height: 720px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(../../assets/images/earth01.png) no-repeat center / 100%;
}

.bg-circle::before {
    content: '';
    left: 0;
    top: 0;
    background-image: url(../../assets/images/spr_bannerPathway.png);
    background-position: -2436px 0px;
    background-repeat: no-repeat;
    width: 720px;
    height: 720px;
    position: absolute;
    animation: ani_line 40s linear infinite;
}

.bg-circle::after {
    content: '';
    left: 36px;
    top: 23px;
    position: absolute;
    background-image: url(../../assets/images/spr_bannerPathway.png);
    background-position: -2436px -724px;
    background-repeat: no-repeat;
    width: 648px;
    height: 674px;
    animation: ani_line 30s linear infinite reverse;
}

@keyframes ani_line {
    from {
        transform: none;
    }
    to {
        transform: rotate(360deg);
    }
}

.bg-left-line {
    background-image: url(../../assets/images/spr_bannerPathway.png);
    background-position: -925px -2510px;
    background-repeat: no-repeat;
    width: 910px;
    height: 514px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.bg-right-line {
    background-image: url(../../assets/images/spr_bannerPathway.png);
    background-position: 0px -2510px;
    background-repeat: no-repeat;
    width: 920px;
    height: 515px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}